<template>
  <v-app>
    <base-app-bar />

    <base-view />

    <footer-view />
    <mobile-navigation />
  </v-app>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import api from '@/plugins/helpers/api'
  import { request, json } from '@/plugins/helpers/request'
  export default {
    name: 'BaseLayout',
    components: {
      BaseAppBar: () => import('./AppBar'),
      BaseView: () => import('./View'),
      FooterView: () => import('./Footer'),
      MobileNavigation: () => import('./MobileNavigationBar'),
    },
    data: function () {
      return {
        auto: undefined,
      }
    },
    computed: {
      ...mapGetters(['isAuthenticated']),
    },
    mounted: function () {
      this.autoGet()
      this.auto = setInterval(this.autoGet, 10000)
    },
    beforeDestroy: function () {
      clearInterval(this.auto)
    },
    methods: {
      ...mapActions(['GetSystemData']),
      autoGet () {
        this.GetSystemData()
        if (this.isAuthenticated) {
          request.get(api.URL + '/khach-hang')
            .then(json)
            .then(json => {
              if (json.data) {
                this.$store.commit('UpdateUserData', json.data)
              }
            })
            .catch(err => {
              if (err.response.status === 404 || err.response.status === 401 || err.response.status === 403) {
                this.$store.dispatch('AUTH_LOGOUT')
                return
              }
              this.$toast.error(err, { position: 'top-right' })
            })
        }
      },
    },
  }
</script>
